var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        "close-on-click-modal": false
      },
      on: {
        open: function($event) {
          return _vm.handleOpen()
        },
        close: function($event) {
          return _vm.handleClose()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { width: "100%" },
          attrs: {
            inline: true,
            rules: _vm.formRules,
            model: _vm.formData,
            "label-position": "left",
            "label-width": "120px"
          }
        },
        [
          _c(
            "el-form-item",
            { staticStyle: { width: "750px" }, attrs: { prop: "vendor" } },
            [
              _c("el-input", {
                staticStyle: { width: "600px" },
                attrs: {
                  type: "textarea",
                  rows: 15,
                  placeholder: "License Info"
                },
                model: {
                  value: _vm.formData.info,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "info", $$v)
                  },
                  expression: "formData.info"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("license.approver.cancel")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
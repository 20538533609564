import request from '@/utils/request';
export var findHistory = function findHistory(params) {
  return request({
    url: '/license/api/v1/admin/history',
    method: 'get',
    params: params
  });
};
export var getHistory = function getHistory(id) {
  return request({
    url: "/license/api/v1/admin/history/".concat(id),
    method: 'get'
  });
};
import request from '@/utils/request';
export var defaultLicenseApprover = {
  clinic: {
    unlimited: false,
    appAllowed: 0,
    smartGlove: 0,
    smartKids: 0,
    smartBoard: 0,
    smartBalance: 0,
    smartTable: 0
  },
  comcog: {
    unlimited: false,
    appAllowed: 0,
    serviceAllowed: 0
  },
  comcogAdmin: {
    unlimited: false,
    appAllowed: 0,
    serviceAllowed: 0
  },
  comcogPublic: {
    unlimited: false,
    appAllowed: 0,
    serviceAllowed: 0
  },
  email: '',
  unlimited: false,
  active: true
};
export var findApprovers = function findApprovers(params) {
  return request({
    url: '/license/api/v1/admin/approvers',
    method: 'get',
    params: params
  });
};
export var getApprover = function getApprover(id) {
  return request({
    url: "/license/api/v1/admin/approvers/".concat(id),
    method: 'get'
  });
};
export var getApproverSummary = function getApproverSummary(id) {
  return request({
    url: "/license/api/v1/admin/approvers/".concat(id, "/summary"),
    method: 'get'
  });
};
export var createApprover = function createApprover(data) {
  return request({
    url: '/license/api/v1/admin/approvers',
    method: 'post',
    data: data
  });
};
export var updateApprover = function updateApprover(data) {
  return request({
    url: "/license/api/v1/admin/approvers/".concat(data.id),
    method: 'put',
    data: data
  });
};
export var deleteApprover = function deleteApprover(id) {
  return request({
    url: "/license/api/v1/admin/approvers/".concat(id),
    method: 'delete'
  });
};
export var activate = function activate(id) {
  return request({
    url: "/license/api/v1/admin/approvers/".concat(id, "/active"),
    method: 'put'
  });
};
export var inactivate = function inactivate(id) {
  return request({
    url: "/license/api/v1/admin/approvers/".concat(id, "/inactive"),
    method: 'put'
  });
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        "close-on-click-modal": false
      },
      on: {
        open: function($event) {
          return _vm.handleOpen()
        },
        close: function($event) {
          return _vm.handleClose()
        }
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { width: "100%" },
          attrs: {
            inline: true,
            rules: _vm.formRules,
            model: _vm.formData,
            "label-position": "left",
            "label-width": "130px"
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("license.approver.email"), prop: "email" }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "e-mail" },
                model: {
                  value: _vm.formData.email,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "email", $$v)
                  },
                  expression: "formData.email"
                }
              })
            ],
            1
          ),
          !_vm.formData.unlimited
            ? [
                _c(
                  "el-card",
                  { staticClass: "box-card", staticStyle: { width: "100%" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        staticStyle: { padding: "0px 0px" },
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_c("label", [_vm._v("Clinic2")])]
                    ),
                    !_vm.formData.clinic.unlimited
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.clinic.appAllowed"
                                ),
                                prop: "clinic.appAllowed"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Allowed Count" },
                                model: {
                                  value: _vm.formData.clinic.appAllowed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.clinic,
                                      "appAllowed",
                                      $$v
                                    )
                                  },
                                  expression: "formData.clinic.appAllowed"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.clinic.smartGlove"
                                ),
                                prop: "clinic.smartGlove"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Smart Glove Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.clinic.smartGlove,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.clinic,
                                      "smartGlove",
                                      $$v
                                    )
                                  },
                                  expression: "formData.clinic.smartGlove"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.clinic.smartKids"
                                ),
                                prop: "clinic.smartKids"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Smart Kids Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.clinic.smartKids,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.clinic,
                                      "smartKids",
                                      $$v
                                    )
                                  },
                                  expression: "formData.clinic.smartKids"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.clinic.smartBoard"
                                ),
                                prop: "clinic.smartBoard"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Smart Board Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.clinic.smartBoard,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.clinic,
                                      "smartBoard",
                                      $$v
                                    )
                                  },
                                  expression: "formData.clinic.smartBoard"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.clinic.smartBalance"
                                ),
                                prop: "clinic.smartBalance"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Smart Balance Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.clinic.smartBalance,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.clinic,
                                      "smartBalance",
                                      $$v
                                    )
                                  },
                                  expression: "formData.clinic.smartBalance"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.clinic.smartTable"
                                ),
                                prop: "clinic.smartTable"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Smart Table Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.clinic.smartTable,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.clinic,
                                      "smartTable",
                                      $$v
                                    )
                                  },
                                  expression: "formData.clinic.smartTable"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "el-card",
                  { staticClass: "box-card", staticStyle: { width: "100%" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        staticStyle: { padding: "0px 0px" },
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_c("label", [_vm._v("Comcog")])]
                    ),
                    !_vm.formData.comcog.unlimited
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.comcog.appCount"
                                ),
                                prop: "comcog.appAllowed"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Comcog Allowed Count" },
                                model: {
                                  value: _vm.formData.comcog.appAllowed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.comcog,
                                      "appAllowed",
                                      $$v
                                    )
                                  },
                                  expression: "formData.comcog.appAllowed"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.comcog.serviceAllowed"
                                ),
                                prop: "comcog.serviceAllowed"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Comcog Content Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.comcog.serviceAllowed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.comcog,
                                      "serviceAllowed",
                                      $$v
                                    )
                                  },
                                  expression: "formData.comcog.serviceAllowed"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "el-card",
                  { staticClass: "box-card", staticStyle: { width: "100%" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        staticStyle: { padding: "0px 0px" },
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_c("label", [_vm._v("Comcog Public")])]
                    ),
                    !_vm.formData.comcogPublic.unlimited
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.comcogPublic.appCount"
                                ),
                                prop: "comcogPublic.appAllowed"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Comcog Public Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.comcogPublic.appAllowed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.comcogPublic,
                                      "appAllowed",
                                      $$v
                                    )
                                  },
                                  expression: "formData.comcogPublic.appAllowed"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.comcogPublic.serviceAllowed"
                                ),
                                prop: "comcogPublic.serviceAllowed"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "Comcog Public Content Allowed Count"
                                },
                                model: {
                                  value:
                                    _vm.formData.comcogPublic.serviceAllowed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.comcogPublic,
                                      "serviceAllowed",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.comcogPublic.serviceAllowed"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "el-card",
                  { staticClass: "box-card", staticStyle: { width: "100%" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        staticStyle: { padding: "0px 0px" },
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_c("label", [_vm._v("Comcog Admin")])]
                    ),
                    !_vm.formData.comcogAdmin.unlimited
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.comcogAdmin.appCount"
                                ),
                                prop: "comcogAdmin.appAllowed"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Comcog Admin Allowed Count"
                                },
                                model: {
                                  value: _vm.formData.comcogAdmin.appAllowed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.comcogAdmin,
                                      "appAllowed",
                                      $$v
                                    )
                                  },
                                  expression: "formData.comcogAdmin.appAllowed"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  "license.approver.comcogAdmin.serviceAllowed"
                                ),
                                prop: "comcogAdmin.serviceAllowed"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder:
                                    "Comcog Admin Content Allowed Count"
                                },
                                model: {
                                  value:
                                    _vm.formData.comcogAdmin.serviceAllowed,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.comcogAdmin,
                                      "serviceAllowed",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "formData.comcogAdmin.serviceAllowed"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("license.approver.cancel")) + " ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.action === "create" ? _vm.createData() : _vm.updateData()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("license.approver.confirm")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "Approver Summary",
        "close-on-click-modal": false
      },
      on: {
        open: function($event) {
          return _vm.handleOpen()
        },
        close: function($event) {
          return _vm.handleClose()
        }
      }
    },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, height: "250" }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "app", label: "App", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "type", label: "Type", width: "180" }
          }),
          _c("el-table-column", {
            attrs: { prop: "allowed", label: "Allowed" }
          }),
          _c("el-table-column", { attrs: { prop: "issued", label: "Issued" } })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  return _vm.handleClose()
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("license.approver.close")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
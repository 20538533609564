import request from '@/utils/request';
export var defaultLicense = {
  approverId: 0,
  deviceVendor: '',
  deviceModel: '',
  deviceSerialNumber: '',
  appId: '',
  info: '',
  active: true
};
export var findLicenses = function findLicenses(params) {
  return request({
    url: '/license/api/v1/admin/licenses',
    method: 'get',
    params: params
  });
};
export var getLicense = function getLicense(id) {
  return request({
    url: "/license/api/v1/admin/licenses/".concat(id),
    method: 'get'
  });
};
export var createLicense = function createLicense(data) {
  return request({
    url: '/license/api/v1/admin/licenses',
    method: 'post',
    data: data
  });
};
export var updateLicense = function updateLicense(data) {
  return request({
    url: "/license/api/v1/admin/licenses/".concat(data.id),
    method: 'put',
    data: data
  });
};
export var deleteLicense = function deleteLicense(id) {
  return request({
    url: "/license/api/v1/admin/licenses/".concat(id),
    method: 'delete'
  });
};
export var activate = function activate(id) {
  return request({
    url: "/license/api/v1/admin/licenses/".concat(id, "/active"),
    method: 'put'
  });
};
export var inactivate = function inactivate(id) {
  return request({
    url: "/license/api/v1/admin/licenses/".concat(id, "/inactive"),
    method: 'put'
  });
};